import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import Authentication from "../../../service/authentication.service";
import UserService from "../../../service/user.service";

import TextInput from "../../../components/TextInput";

import ImagemExporevestir from "../../../assets/images/background/PlataformaDigital.jpg";
import * as messages from "../../../validations/messages";

import "../../../validations/customs.validation";
import "./styles.scss";
// import bgLogo from "../../../assets/images/bg-login.svg";

export default function ChangePassword() {
  const history = useHistory();
  const [hasError, setHasError] = useState("");
  const [loading, setLoading] = useState(false);
  const [validationPass, setValidationPass] = useState({
    numero: false,
    letraMinuscula: false,
    letraMaiuscula: false,
    caracterEspecial: false,
    length: false,
  });

  const isChangePassword =
    Authentication._user && Authentication._user.changePassword;

  if (!isChangePassword) {
    history.push("/");
  }

  const userService = new UserService();

  function validPass(event) {
    const value = event.target.value;

    const numero = /^(?=.*\d)/.test(value);
    const letraMinuscula = /(?=.*[a-z])/.test(value);
    const letraMaiuscula = /(?=.*[A-Z])/.test(value);
    const caracterEspecial = /(?=.*[!@#$%<^&*?=])/.test(value);
    const length = value.length >= 6;

    setValidationPass({
      numero,
      letraMinuscula,
      letraMaiuscula,
      caracterEspecial,
      length,
    });
  }

  async function onSubmit(data) {
    setLoading(true);
    setHasError("");

    userService
      .changePassword({
        senha: data.password,
        conf_senha: data.confirmPassword,
      })
      .then((response) => {
        Authentication._user = {
          ...Authentication._user,
          changePassword: false,
        };
        history.push("/");
      })
      .catch(({ response }) => {
        setHasError(response.data.msg);
        setLoading(false);
      });
  }

  return (
    <div id="page-login-background">
      <div id="page-login">
        <div className="container">
          <div className="box-login">
            <div className="login-image">
              <img src={ImagemExporevestir} alt="ExpoRevestir" />
            </div>

            <div className="login-form">
              <div className="row-right">
                <div className="flex-box-login">
                  <div className="box-login">
                    <Formik
                      initialValues={{
                        password: "",
                        confirmPassword: "",
                      }}
                      validationSchema={Yup.object({
                        password: Yup.string()
                          .min(6, messages.MIN_LENGTH)
                          .password()
                          .max(20, messages.MAX_LENGTH)
                          .required(messages.REQUIRED),
                        confirmPassword: Yup.string()
                          .required(messages.REQUIRED)
                          .test(
                            "passwords-match",
                            '"Senha" e "Confirme a senha" devem ser iguais',
                            function (value) {
                              return this.parent.password === value;
                            }
                          ),
                      })}
                      onSubmit={(values) => {
                        setTimeout(() => {
                          onSubmit(values);
                        }, 400);
                      }}
                    >
                      <Form className="form" autoComplete="off">
                        <div className="boas-vindas">
                          <h1 className="title-boas-vindas">Alterar Senha</h1>
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            marginBottom: 20,
                            fontFamily: "Raleway",
                            fontSize: "17px",
                            color: "#000",
                          }}
                        >
                          Digite uma nova senha antes de acessar o sistema.
                        </div>

                        {hasError && (
                          <div className="error-menssage">{hasError}</div>
                        )}

                        <div className="box">
                          <TextInput
                            placeholder="Senha"
                            name="password"
                            type="password"
                            className="box-input"
                            onKeyUp={validPass}
                          />
                        </div>

                        <div className="box">
                          <TextInput
                            placeholder="Confirme a senha"
                            name="confirmPassword"
                            className="box-input"
                            type="password"
                          />
                        </div>

                        <button
                          loading={loading}
                          type="submit"
                          styles="w-all"
                          className="button-enter"
                          style={{ marginTop: "20px" }}
                        >
                          {" "}
                          Atualizar senha{" "}
                        </button>

                        <div className="info-pass">
                          <div className="title-list">
                            <h3>A senha deve conter:</h3>
                          </div>
                          <div>
                            <div
                              className={
                                validationPass.numero && "check-success"
                              }
                            >
                              <div className="check">
                                {!validationPass.numero && (
                                  <span className="check-text">-</span>
                                )}
                                {validationPass.numero && (
                                  <i className="pi pi-check"></i>
                                )}
                              </div>
                              Mínimo 1 número
                            </div>
                            <div
                              className={
                                validationPass.letraMinuscula && "check-success"
                              }
                            >
                              <div className="check">
                                {!validationPass.letraMinuscula && (
                                  <span className="check-text">-</span>
                                )}
                                {validationPass.letraMinuscula && (
                                  <i className="pi pi-check"></i>
                                )}
                              </div>
                              Mínimo 1 letra minúscula
                            </div>
                            <div
                              className={
                                validationPass.letraMaiuscula && "check-success"
                              }
                            >
                              <div className="check">
                                {!validationPass.letraMaiuscula && (
                                  <span className="check-text">-</span>
                                )}
                                {validationPass.letraMaiuscula && (
                                  <i className="pi pi-check"></i>
                                )}
                              </div>
                              Mínimo 1 letra maiúscula
                            </div>
                            <div
                              className={
                                validationPass.caracterEspecial &&
                                "check-success"
                              }
                            >
                              <div className="check">
                                {!validationPass.caracterEspecial && (
                                  <span className="check-text">-</span>
                                )}
                                {validationPass.caracterEspecial && (
                                  <i className="pi pi-check"></i>
                                )}
                              </div>
                              Mínimo 1 caractere especial {"(!@#$%<^&*?=)"}
                            </div>
                            <div
                              className={
                                validationPass.length && "check-success"
                              }
                            >
                              <div className="check">
                                {!validationPass.length && (
                                  <span className="check-text">-</span>
                                )}
                                {validationPass.length && (
                                  <i className="pi pi-check"></i>
                                )}
                              </div>
                              Mínimo 6 caracteres
                            </div>
                          </div>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
