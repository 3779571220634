/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Formik } from "formik";
import { InputSwitch } from "primereact/inputswitch";
import React, { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import * as Yup from "yup";
import AlertMenssage from "../../../components/AlertMenssage";
import ButtonSave from "../../../components/ButtonSave";
import ImageeInputPreview from "../../../components/ImageInputPreview";
import TextInput from "../../../components/TextInput";
import ConfirmationDialog from "../../../components/dialogs/confirmation-dialog";
import AlertService from "../../../service/alert.service";
import UserProfileService from "../../../service/user-profile.service";
import imageIconDefault from "../../../utils/image-icon-default";
import * as messages from "./../../../validations/messages";

export default function ConfiguracaoPerfilForm() {
  const _perfilService = new UserProfileService();

  const { id: idPerfil } = useParams();
  const history = useHistory();
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [perfilDisabled, setPerfilDisabled] = useState(false);
  const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false);
  const [displayDisabledDialog, setDisplayDisabledDialog] = useState(false);
  const [imagemLogo, setImagemLogo] = useState(imageIconDefault());
  const [imageFile, setImageFile] = useState([]);

  // Funcionalidades
  const [certificado, setCertificado] = useState(true);

  const [valuesForm, setValuesForm] = useState({
    profile: "",
  });

  useEffect(() => {
    setIsEdit(!!idPerfil);

    const init = async () => {
      if (idPerfil) {
        const perfil = await _perfilService.read(idPerfil);
        setValuesForm(perfil);
        setPerfilDisabled(perfil.disabled);
        setCertificado(perfil.certificado);

        if (perfil.imageLogo) {
          setImagemLogo(_perfilService.linkImage(idPerfil));
        }
      }
    };

    init();
  }, []);

  async function handleDelete() {
    try {
      await _perfilService.delete(idPerfil);
      history.push(`/configuracoes/perfis`);
    } catch ({ response }) {
      AlertService.warn(response.data.msg);
    } finally {
      setDisplayDeleteDialog(false);
    }
  }

  async function handleDisabled() {
    const isDisabled = !perfilDisabled;

    const data = {
      id: idPerfil,
      disabled: isDisabled,
    };

    await _perfilService.update(data);

    setPerfilDisabled(isDisabled);
    setDisplayDisabledDialog(false);
    AlertService.success(`Perfil ${isDisabled ? "desativado" : "ativado"}`);
  }

  const changePhoto = (value) => {
    setImageFile(value);
  };

  const updatePhoto = async (id = idPerfil) => {
    await _perfilService.uploadImagens(imageFile, id);
  };

  const onSubmit = async (values) => {
    setLoading(true);

    values.certificado = certificado;

    if (isEdit) {
      // Atualizar
      try {
        values.disabled = perfilDisabled;
        await _perfilService.update(values);
        await updatePhoto();
        AlertService.success("Curso atualizado");
      } catch ({ response }) {
        AlertService.warn(response.data.msg);
      } finally {
        setLoading(false);
      }
    } else {
      // Criar
      try {
        const perfilResponse = await _perfilService.create(values);
        await updatePhoto(perfilResponse.id);
        history.push(`/configuracoes/perfis?acao=criado`);
      } catch ({ response }) {
        AlertService.warn(response.data.msg);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <ConfirmationDialog
        header="Atenção!"
        action="Apagar este Perfil?"
        display={displayDeleteDialog}
        onClose={() => setDisplayDeleteDialog(false)}
        onConfirm={handleDelete}
      />

      <ConfirmationDialog
        header="Atenção!"
        action={`${perfilDisabled ? "Ativar" : "Desativar"}  este Perfil?`}
        display={displayDisabledDialog}
        onClose={() => setDisplayDisabledDialog(false)}
        onConfirm={handleDisabled}
      />

      <div className="card">
        <h1 className="title-page">
          {isEdit ? "Editar" : "Adicionar"} Perfil
          {perfilDisabled ? (
            <>
              {" "}
              - <span className="text-desativado">Desativado</span>
            </>
          ) : null}
        </h1>

        <hr className="hr-header" />

        <Formik
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            profile: Yup.string().required(messages.REQUIRED).nullable(),
          })}
          initialValues={valuesForm}
          onSubmit={onSubmit}
        >
          {(props) => {
            return (
              <Form autoComplete="off">
                <div
                  className="wrapper-form"
                  style={{ paddingLeft: "0.5em", maxWidth: "820px" }}
                >
                  <div>
                    <div className="p-grid">
                      <TextInput
                        classes="p-col-4"
                        name="profile"
                        className="input"
                        label="Perfil"
                        type="text"
                        required
                      />
                    </div>

                    <div className="box">
                      <div className="flex-box-profile">
                        <div className="box-profile">
                          <ImageeInputPreview
                            height={250}
                            width={250}
                            tamanhoEspecifico={true}
                            image={imagemLogo}
                            changeImage={(e) => changePhoto(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div style={{ marginTop: "50px" }}>
                    <hr style={{ opacity: "30%" }} />

                    <div className="p-grid">
                      <div className="p-col-12">
                        <h2>Funcionalidades</h2>
                      </div>

                      <div className="input-switch">
                        <InputSwitch
                          checked={certificado}
                          inputId="certificado"
                          onChange={(e) => {
                            e.preventDefault();
                            setCertificado(e.target.value);
                          }}
                        />
                        <label htmlFor="certificado">Certificado</label>
                      </div>
                    </div>
                  </div>

                  <div className="btns-inline" style={{ marginTop: 50 }}>
                    <div className="btn-medium">
                      <ButtonSave
                        label={isEdit ? "Atualizar" : "Adicionar"}
                        type="submit"
                        loading={loading}
                      />
                    </div>
                    {isEdit ? (
                      <>
                        <div className="btn-medium">
                          <button
                            className="button-disable"
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              setDisplayDisabledDialog(true);
                            }}
                          >
                            {perfilDisabled ? "Ativar" : "Desativar"}
                          </button>
                        </div>
                        <div className="btn-medium">
                          <div
                            className="btns-inline"
                            style={{ justifyContent: "space-evenly" }}
                          >
                            <button
                              className="button-delete"
                              styles="p-button-secondary"
                              onClick={(e) => {
                                e.preventDefault();
                                setDisplayDeleteDialog(true);
                              }}
                            >
                              Excluir
                            </button>
                          </div>
                        </div>
                      </>
                    ) : null}
                    <div className="btn-medium">
                      <button
                        className="button-voltar"
                        onClick={() => {
                          history.push(`/configuracoes/perfis`);
                        }}
                      >
                        Voltar
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>

        <AlertMenssage />
      </div>
    </>
  );
}
