import { Form, Formik } from "formik";
import { Checkbox } from "primereact/checkbox";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import iconLoginEmail from "../../../assets/icons/email.svg";
import iconLoginPassword from "../../../assets/icons/lock.svg";
import ImagemExporevestir from "../../../assets/images/background/PlataformaDigital.jpg";
import TextInput from "../../../components/TextInput";
import Authentication from "../../../service/authentication.service";
import EditionService from "../../../service/edition.service";
import { AppContext } from "../../../store";
import "./styles.scss";
// import { format } from "date-fns";
// import { ptBR } from "date-fns/locale";

export default function Login() {
  const [userLogin, setUserLogin] = useState("");
  const history = useHistory();
  const [hasError, setHasError] = useState("");
  const [errorMenssage, setErrorMenssage] = useState("");
  const [loading, setloading] = useState(false);
  const [isRememberLogin, setIsRememberLogin] = useState(false);
  const { setUser } = useContext(AppContext);
  /* eslint-disable-next-line no-unused-vars */
  const [edicaoAtiva, setEdicaoAtiva] = useState();

  function saveLogin(remember, login) {
    if (remember) {
      localStorage.setItem("userLogin", login);
      localStorage.setItem("rememberUserLogin", true);
    } else {
      localStorage.removeItem("userLogin");
      localStorage.removeItem("rememberUserLogin");
    }
  }

  async function handleSignIn(data) {
    setErrorMenssage("");

    try {
      setloading(true);
      await new Authentication().singIn(data.user, data.password);

      if (
        Authentication._user.profile &&
        Authentication._user.profile.id === 4 // Não trocar deve ser sempre 4
      ) {
        throw new Error("Acesso negado.");
      }

      setUser(Authentication._user);
      saveLogin(isRememberLogin, data.user);
      setHasError(false);
      history.push("/painel-controle");
    } catch (error) {
      setloading(false);

      if (error.response && error.response.data) {
        setHasError(true);
        setErrorMenssage(error.response.data.msg);
      } else {
        setErrorMenssage(
          error.message || "Ocorreu um erro ao tentar realizar o login."
        );
      }
    }
  }

  const errorMessage = (
    <div className="error-menssage">
      Login ou Senha Incorreto. Tente Novamente
    </div>
  );

  useEffect(() => {
    if (localStorage.getItem("rememberUserLogin")) {
      setUserLogin(localStorage.getItem("userLogin"));
      setIsRememberLogin(true);
    }

    async function init() {
      const _editionService = new EditionService();
      const edicaoAtivaResponse = await _editionService.carregarEdicaoAtiva();

      setEdicaoAtiva(edicaoAtivaResponse);
    }

    init();
  }, []);

  return (
    <div id="page-login-background">
      <div id="page-login">
        <div className="container">
          <div className="box-login">
            <div className="login-image">
              <img src={ImagemExporevestir} alt="ExpoRevestir" />
            </div>

            <div className="login-form">
              <div className="flex-box-login">
                <div className="box-login">
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      user: userLogin,
                      password: "",
                    }}
                    validationSchema={Yup.object({
                      user: Yup.string().required("*Obrigatório"),
                      password: Yup.string().required("*Obrigatório"),
                    })}
                    onSubmit={(values) => {
                      handleSignIn(values);
                    }}
                  >
                    <Form className="form" autoComplete="off">
                      <div className="boas-vindas">
                        <h1 className="title-boas-vindas">Conecte-se</h1>
                      </div>
                      <div className="box">
                        <TextInput
                          iconleft={iconLoginEmail}
                          className="box-input"
                          name="user"
                          placeholder="E-mail"
                        />

                        <div id="box-input-password">
                          <TextInput
                            iconleft={iconLoginPassword}
                            className="box-input"
                            name="password"
                            placeholder="Senha"
                            type="password"
                          />
                        </div>
                        <div className="check-login">
                          <Checkbox
                            inputId="checkLogin"
                            onChange={() =>
                              setIsRememberLogin(!isRememberLogin)
                            }
                            checked={isRememberLogin}
                          />
                          <label htmlFor="checkLogin" className="text">
                            Mantenha-me conectado
                          </label>
                        </div>
                      </div>

                      <button
                        loading={loading.true}
                        type="submit"
                        styles="w-all"
                        className="button-enter"
                      >
                        Entrar
                      </button>

                      <div
                        className="text-link"
                        onClick={() => history.push("/recuperar-senha")}
                      >
                        Esqueceu a senha? Clique aqui
                      </div>

                      {hasError ? errorMessage : null}
                      {errorMenssage && (
                        <div className="error-menssage">{errorMenssage}</div>
                      )}
                    </Form>
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
