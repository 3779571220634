export default class UserProfileSerializer {
  fromJson(json) {
    const state = {
      id: json.id_usuario_perfil,
      profile: json.perfil,
      imageLogo: json.imagem_logo,
      value: {
        id: json.id_usuario_perfil,
        profile: json.perfil,
      },
      label: json.perfil,
      disabled: json.disabled,
      certificado: json.certificado,
    };

    return state;
  }

  toJson(profile) {
    const profileToJson = {};

    Object.assign(
      profileToJson,
      profile.profile && { perfil: profile.profile },
      { disabled: profile.disabled },
      { certificado: profile.certificado }
    );

    return profileToJson;
  }
}
